<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="mt-4 flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <div
            @click="openModal"
            class="relative flex-initial cursor-pointer rounded-md shadow-sm"
          >
            <button
              type="button"
              class="absolute inset-y-0 top-1 right-3 flex cursor-pointer items-center pl-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              :value="filter.gudang.code"
              type="text"
              name="search"
              disabled
              id="search"
              class="block w-full cursor-pointer rounded-md border-gray-300 pl-4 focus:border-green-500 focus:ring-green-500 sm:text-sm"
              :placeholder="
                selectedStockist
                  ? selectedStockist.attributes.name
                  : 'Pilih Stockist'
              "
            />
          </div>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getStocks.meta.page.total"
              :perPage="getStocks.meta.page.perPage"
              :currentPage="getStocks.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
              :is-empty="!getStocks.data.length"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Stock ORDERED
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Gudang
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getStocks.data.length">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getStocks.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailStock(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.product_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.product_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.stock_qty }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.stock_ordered }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.warehouse_code }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
        <Modal
          :showing="openDetail"
          @close="openDetail = !openDetail"
          :showClose="openDetail"
          :backgroundClose="true"
          size="max-w-6xl"
        >
          <template v-if="dataStock.attributes">
            <div class="flex justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Detail Stock
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Berdasarkan data stock yang telah dibuat
                </p>
              </div>
              <div>
                <img src="@/assets/images/logo.png" alt="" class="w-52" />
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl
                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6"
              >
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataStock.attributes.product_code }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataStock.attributes.product_name }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Stock</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataStock.attributes.stock_qty }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Status Order</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <span
                      v-if="dataStock.attributes.is_orderable"
                      class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                    >
                      Open
                    </span>
                    <span
                      v-else
                      class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                    >
                      Close
                    </span>
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">
                    Status Pre Order
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <span
                      v-if="dataStock.attributes.is_pre_orderable"
                      class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                    >
                      Open
                    </span>
                    <span
                      v-else
                      class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                    >
                      Close
                    </span>
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Status Izin</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <span
                      v-if="dataStock.attributes.license_status"
                      class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                    >
                      Sudah Berizin
                    </span>
                    <span
                      v-else
                      class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                    >
                      Belum Berizin
                    </span>
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Total Berat</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ dataStock.attributes.total_weight }}
                  </dd>
                </div>
              </dl>
            </div>
            <div class="flex justify-end">
              <button
                @click="openDetail = !openDetail"
                type="button"
                class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </template>
        </Modal>

        <!-- Pilih Stockist -->
        <Modal
          :showing="openListStockist"
          @close="openListStockist = !openListStockist"
          :showClose="openListStockist"
          :backgroundClose="true"
          size="max-w-6xl"
        >
          <div class="mb-6 flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Daftar Stockist
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Berdasarkan data stockist yang telah dibuat
                </p>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-200 py-6">
            <div class="flex justify-end">
              <base-search
                v-model="searchStockist"
                @input="debounceSearchStockist"
                placeholder="Cari kode atau nama"
              />
            </div>
            <div class="my-2 flex sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              >
                <Datatable
                  :total="getStockists.meta.page.total"
                  :perPage="getStockists.meta.page.perPage"
                  :currentPage="getStockists.meta.page.currentPage"
                  @pagechanged="onPageChangeStockist"
                >
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode Stockist
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Nama Stockist
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Kode Gudang
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody>
                      <tr
                        class="cursor-pointer bg-white hover:bg-green-100"
                        v-for="(data, dataIdx) in getStockists.data"
                        :key="data.id"
                        :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        @click="data ? changeStockist(data) : null"
                      >
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                        >
                          {{ data.attributes.code }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ data.attributes.name }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{
                            data.relationships['current-warehouse'].data
                              ? getRelationships(
                                  getStockists,
                                  data.relationships['current-warehouse'].data
                                    .id
                                ).attributes.name
                              : '-'
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    BaseSearch,
    Modal,
  },
  data() {
    return {
      search: null,
      searchStockist: '',
      openDetail: false,
      openListStockist: false,
      dropdown: false,
      dataStock: {},
      selectedStockist: null,
      filter: {
        warehouseId: null,
        gudang: {
          id: '',
          code: '',
          name: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'stocks/getLoading',
      getStocks: 'stocks/getStocks',
      getWarehouseByOffice: 'warehouses/getWarehouseByOffice',
      getWarehouses: 'warehouses/getWarehouses',
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getStockists: 'stockists/getStockists',
      me: 'auth/getUser',
    }),
    fetchStocksParams() {
      return {
        'filter[warehouse_type]': 'stockist',
        pageSize: 5,
        pageNumber: 1,
        search: this.search,
        warehouseId: this.filter.warehouseId,
      };
    },
    fetchStockistsParams() {
      return {
        pageSize: 5,
        pageNumber: 1,
        search: this.searchStockist,
        include: 'current-warehouse',
      };
    },
  },
  methods: {
    ...mapActions({
      fetchStocks: 'stocks/fetchStocks',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      fetchStockists: 'stockists/fetchStockists',
    }),
    openModal() {
      this.openListStockist = !this.openListStockist;
      this.fetchStockists(this.fetchStockistsParams);
    },
    getRelationships(res, id) {
      const included = res.included.filter(function (el) {
        return el.id === id;
      });
      return included[0];
    },
    onPageChangeProducts(page) {
      this.fetchStocks({
        ...this.fetchStocksParams,
        pageNumber: page,
      });
    },
    onPageChangeStockist(page) {
      this.fetchStockists({
        ...this.fetchStockistsParams,
        pageNumber: page,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchStocks({
        ...this.fetchStocksParams,
      });
    }, 300),
    debounceSearchStockist: debounce(function () {
      this.fetchStockists(this.fetchStockistsParams);
    }, 300),
    detailStock(stock) {
      this.dataStock = stock;
      this.openDetail = !this.openDetail;
    },
    changeStockist(data) {
      const warehouse = data.relationships['current-warehouse'].data
        ? this.getRelationships(
            this.getStockists,
            data.relationships['current-warehouse'].data.id
          )
        : null;

      this.selectedStockist = data;

      this.filter.warehouseId = warehouse.id;

      this.fetchStocks(this.fetchStocksParams);
      this.openListStockist = !this.openListStockist;
      this.searchStockist = '';
    },
  },
  created() {
    this.fetchStocks({
      ...this.fetchStocksParams,
      pageNumber: 1,
    });
  },
};
</script>
